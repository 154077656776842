import React from "react";
import Navbar from "../../page/home/navbar";
import aws01 from "../../assets/Services/AWS/aws_01.webp";
import aws02 from "../../assets/Services/AWS/aws02.webp";
import aws03 from "../../assets/Services/AWS/aws03.webp";
import aws04 from "../../assets/Services/AWS/aws04.webp";
import aws05 from "../../assets/Services/AWS/aws05.webp";
import aws06 from "../../assets/Services/AWS/aws06.webp";
import { Fade } from "react-awesome-reveal";
import { Avatar, Grid, Typography } from "@mui/material";
import Footer from "../../page/home/Footer";
import { useState } from "react";
import DialogOpen from "../../components/DialogOpen";

function AwsIot() {
  const popUpData = [
    {
      id: 1,
      title: "AWS IoT Core Services",
      content:
        "AWS IoT Core forms the backbone of your IoT ecosystem. We help you seamlessly connect and manage your devices, enabling secure communication between devices and the cloud. From industrial equipment to consumer devices, our experts ensure that your devices are not just connected, but they're also protected with robust security measures.",
      service: [
        "Schema design and data modeling for optimal performance.",
        "Secure communication using MQTT and HTTPS protocols.",
        "Real-time device monitoring and management.",
        "Integration with AWS Identity and Access Management (IAM) for access control.",
      ],
    },
    {
      id: 2,
      title: "DynamoDB Services",
      content:
        "Harness the scalability and flexibility of AWS DynamoDB to store and manage your IoT data. With our expertise, you can build databases that scale to handle massive amounts of data generated by your devices. Our solution architects design DynamoDB schemas that optimize query performance, ensuring that you get the insights you need in real time.",
      service: [
        "Schema design and data modeling for optimal performance.",
        "Capacity planning and auto-scaling to accommodate fluctuating workloads.",
        "Integration with AWS Lambda for automated data processing.",
        "Data retention and archiving strategies.",
      ],
    },

    {
      id: 3,
      title: "Python Lambda Function Services",
      content:
        "Efficiency is key in IoT operations. Our Python Lambda function development services allow you to automate data processing, analysis, and transformation in response to IoT events. We create custom Lambda functions that integrate seamlessly with your AWS IoT environment, ensuring that your workflows are streamlined and optimized.",
      service: [
        "Event-driven Lambda function development.",
        "Data transformation, validation, and enrichment.",
        "Integration with AWS services such as DynamoDB and S3.",
        "Real-time data analytics using Lambda and other AWS services.",
      ],
    },

    {
      id: 4,
      title: "Amazon Timestream Services",
      content:
        "Amazon Timestream is the ideal solution for managing and querying time-series data generated by your IoT devices. Our experts help you set up and configure Timestream databases, allowing you to efficiently store, analyze, and visualize time-stamped data. With Timestream, you can unlock valuable insights and trends from your IoT-generated data.",
      service: [
        "Database creation and schema design for time-series data.",
        "Data ingestion and batch processing setup.",
        "Integration with visualization tools and business intelligence platforms.",
        "Query optimization for fast and accurate data retrieval.",
      ],
    },
    {
      id: 5,
      title: "API Gateway Services",
      content:
        "API Gateway acts as the bridge between your IoT devices and external applications. Our API Gateway services enable you to create and manage APIs that allow secure and controlled access to your IoT data. Whether you're exposing data to partners, customers, or internal applications, we ensure that your APIs are secure, scalable, and reliable.",
      service: [
        "API design and creation to expose IoT data.",
        "Secure API authentication and authorization. Rate limiting and throttling to control access.",
        "Integration with AWS Lambda for real-time data processing.",
      ],
    },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState([]);

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <div>
      <Navbar />

      <section className="homepage">
        <img
          src={aws01}
          alt="Digikloud Company"
          className="services_background_image"
        />
        <header className="awsiotTopHead">
          <Fade direction="up" duration={2000} triggerOnce={true}>
            <Typography variant="h1">AWS IOT Services</Typography>
            <Typography variant="h4">
              Welcome to DiGiKloud - Your Gateway to AWS IoT Excellence
            </Typography>
          </Fade>
        </header>
      </section>

      <section className="productpagecontainer">
        <article className="thingskloud_info">
          <Typography variant="h3" className="highlight_text">
            Deliver smart, connected solutions for growth
          </Typography>
          <article className="awsServicesContent">
            <Typography variant="h5">
              At DiGiKloud , we specialize in providing a comprehensive suite of
              AWS IoT services that empower businesses to harness the full
              potential of the Internet of Things (IoT) technology. With our
              expertise in AWS IoT Core, DynamoDB, Python Lambda functions,
              Amazon Timestream, and API Gateway, we offer you a seamless path
              towards building smart, connected solutions that drive innovation,
              efficiency, and growth.
            </Typography>
          </article>
        </article>
      </section>

      <section className="byoppagecontainer">
        <Grid container style={{ width: "80%" }}>
          <Grid item xs={12} md={4} lg={4}>
            <Fade direction="up" triggerOnce={true} duration={1000}>
              <article
                className="awsIot_services_container"
                onClick={() => {
                  setOpenPopup(true);
                  setData(popUpData[0]);
                }}
              >
                <Avatar sx={{ width: "50%", height: "50%" }} src={aws02} />
                <Typography variant="h6"> AWS IoT Core</Typography>
                <Typography variant="p">
                  Unleash the Power of Connected Devices
                </Typography>
              </article>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Fade direction="up" triggerOnce={true} duration={2000}>
              <article
                className="awsIot_services_container"
                onClick={() => {
                  setOpenPopup(true);
                  setData(popUpData[1]);
                }}
              >
                <Avatar sx={{ width: "50%", height: "50%" }} src={aws03} />
                <Typography variant="h6"> DynamoDB</Typography>
                <Typography variant="p">
                  Efficient Data Storage for IoT
                </Typography>
              </article>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Fade direction="up" triggerOnce={true} duration={2000}>
              <article
                className="awsIot_services_container"
                onClick={() => {
                  setOpenPopup(true);
                  setData(popUpData[2]);
                }}
              >
                <Avatar sx={{ width: "50%", height: "50%" }} src={aws04} />
                <Typography variant="h6">Python Lambda Functions</Typography>
                <Typography variant="p">Automate Your IoT Workflows</Typography>
              </article>
            </Fade>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Fade direction="up" triggerOnce={true} duration={2000}>
              <article
                className="awsIot_services_container"
                onClick={() => {
                  setOpenPopup(true);
                  setData(popUpData[3]);
                }}
              >
                <Avatar sx={{ width: "40%", height: "40%" }} src={aws05} />
                <Typography variant="h6">Amazon Timestream</Typography>
                <Typography variant="p">
                  Time-Series Data Made Simple
                </Typography>
              </article>
            </Fade>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Fade direction="up" triggerOnce={true} duration={2000}>
              <article
                className="awsIot_services_container"
                onClick={() => {
                  setOpenPopup(true);
                  setData(popUpData[4]);
                }}
              >
                <Avatar sx={{ width: "40%", height: "40%" }} src={aws06} />
                <Typography variant="h6">API Gateway</Typography>
                <Typography variant="p">
                  Seamless Connectivity for Your IoT Ecosystem
                </Typography>
              </article>
            </Fade>
          </Grid>
        </Grid>
      </section>

      {/* <section className="byoppagecontainer">
        <Grid container className="byop_deails">
          <Grid item xs={12} md={12} className="awsIoT_contact">
            <Fade direction="up" triggerOnce={true}>
              <article>
                <Typography
                  variant="h3"
                  style={{ textAlign: "center" }}
                  className="highlight_text"
                >
                  Get Connected Today
                </Typography>
                <Typography variant="h4">
                  Elevate your Industry 4.0 journey with NextGen IoT Services.
                  Experience the power of real-time monitoring and stay in
                  control with instant email and SMS notifications. Contact us
                  to explore how our tailored solutions can drive your business
                  forward.
                </Typography>
              </article>
              <Button
                aria-label="book demo"
                style={{
                  borderRadius: 35,
                  backgroundColor: "#00b894",
                  color: "white",
                  margin: "5px",
                  fontSize: "18px",
                }}
                variant="contained"
              >
                Book Demo
              </Button>
            </Fade>
          </Grid>
        </Grid>
      </section> */}

      <section className="productpagecontainer">
        <Fade direction="up" triggerOnce={true}>
          <article className="thingskloud_info">
            <Typography variant="h3" className="highlight_text">
              Get Connected Today
            </Typography>
            <Typography variant="h6">
              Elevate your Industry 4.0 journey with NextGen IoT Services.
              Experience the power of real-time monitoring and stay in control
              with instant email and SMS notifications. Contact us to explore
              how our tailored solutions can drive your business forward.
            </Typography>
            {/* <Button
              aria-label="book demo"
              style={{
                borderRadius: 35,
                backgroundColor: "#00b894",
                color: "white",
                margin: "5px",
                fontSize: "18px",
              }}
              variant="contained"
            >
              Book Demo
            </Button> */}
          </article>
        </Fade>
      </section>
      <Footer />
      <DialogOpen
        openPopup={openPopup}
        onClose={handleClose}
        title={data.title}
        content={data.content}
        contentList={data.service}
      ></DialogOpen>
    </div>
  );
}

export default AwsIot;
